'use client'

import { isServer } from '@tebuto/functions'
import { Router } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'

const siteID = 2
const trackerUrl = 'https://schroeer.matomo.cloud/'
const scriptSrc = 'https://cdn.matomo.cloud/schroeer.matomo.cloud/matomo.js'

declare global {
    interface Window {
        _mtm: any[]
        _paq: any[]
    }
}

export function MatomoProvider() {
    useEffect(() => {
        Router.events.on('routeChangeComplete', Matomo.trackView)
        return () => Router.events.off('routeChangeComplete', Matomo.trackView)
    }, [])

    return (
        <>
            <Matomo.code />
        </>
    )
}

export namespace Matomo {
    const matomo = !isServer() ? window._paq || [] : []

    export function code() {
        return (
            <Script
                strategy="afterInteractive"
                async
                defer
                // biome-ignore lint/security/noDangerouslySetInnerHtml: We dont care about this code here
                dangerouslySetInnerHTML={{
                    __html: `
                    var _paq = window._paq = window._paq || [];
                    _paq.push(['disableCookies']);
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    (function() {
                    var u='${trackerUrl}';
                    _paq.push(['setTrackerUrl', u+'matomo.php']);
                    _paq.push(['setSiteId', ${siteID}]);
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.async=true; g.src='${scriptSrc}'; s.parentNode.insertBefore(g,s);
                    })();
                    `
                }}
            />
        )
    }

    export enum EventCategory {
        BUTTON = 'Button',
        FORM = 'Form',
        VIDEO = 'Video',
        SUBSCRIPTION = 'Subscription'
    }

    export enum EventAction {
        CLICK = 'Click',
        SUBMIT = 'Submit',
        PLAY = 'Play',
        PAUSE = 'Pause',
        PAYMENT = 'Payment'
    }

    interface TrackEventOptions {
        category: EventCategory
        action: EventAction
        name?: string
        value?: number
    }

    export function trackEvent({ category, action, name = '', value = 0 }: TrackEventOptions) {
        if (typeof window !== 'undefined' && window._paq) {
            window._paq.push(['trackEvent', category, action, name, value])
            console.log('Matomo event tracked', { category, action, name, value })
        }
    }

    export function trackView(url: string) {
        if (matomo) {
            matomo.push(['setCustomUrl', url])
            matomo.push(['trackPageView'])
        }
    }
}
